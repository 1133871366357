export const RolesEnum = {
  salesperson: 'salesperson',
  storeperson: 'storeperson',
  akadperson: 'akadperson',
  leader: 'leader',
  manager: 'manager',
  supervisor: 'supervisor',
  admin: 'admin',
}

export const MessageTypeEnum = {
  ERROR: 'ERRO',
  WARNING: 'AVISO',
  SUCCESS: 'SUCESSO',
  INFO: 'INFORMAÇÃO'
}

export const TypeEnum = {
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATE: 'CORPORATE',
  PERSONAL_ACCIDENTS: 'PERSONAL_ACCIDENTS'
}

export const InsurancesEnum = {
  AKAD: 'Akad',
  ICATU: 'Icatu',
  ALFA: 'Alfa',
  ZURICH: 'Zurich',
}

export const OrganizationEnum = {
  TON: 'TON',
  STONE: 'STONE'
}

export const SignatureModeEnum = {
  HASH: 'HASH',
  PROVIDER: 'PROVIDER',
  WHATSAPP: 'WHATSAPP'
}

export const ResourcesEnum = {
  brokers: 'brokers',
  agents: 'agents',
  lifeInsurance: 'life-insurance',
  storeInsurance: 'store-insurance',
  createLeader: 'create-leader',
  createAdmin: 'create-admin',
  changeBroker: 'change-broker',
  listAllBrokers: 'list-all-brokers',
  salesmanDashboard: 'salesman-dashboard',
  leadsDashboard: 'leads-dashboard',
  config: 'config',
  notification: 'notification',
  akadperson: 'akad-person',
  leads: 'leads',
  links: 'links'
}

export const StatusEnum = {
  OPEN: 'OPEN',
  CANCELED: 'CANCELED',
  WAITING_SIGNATURE: 'WAITING_SIGNATURE',
  EXPIRED_TO_SIGN: 'EXPIRED_TO_SIGN',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  SENT_INSURER: 'SENT_INSURER',
  NO_DEAL: 'NO_DEAL',
  WAITING_CREDIT_CARD_FILLING: 'WAITING_CREDIT_CARD_FILLING'

}

export const BusinessSegmentEnum = {
  b4t05av: 'Serviços',
  uikivda: 'Comércio',
  buxoj9: 'Comércio',
  '084ec7i': 'Serviços',
  vig3y5g: 'Serviços',
  u19gti: 'Serviços',
  mcj0nwq: 'Serviços',
  qzgikns: 'Serviços',
  an0qv07: 'Comércio',
  jchypf: 'Comércio',
  ciqwskc: 'Comércio',
  uiq9n5l: 'Serviços',
  wroh3fg: 'Serviços',
  jv8n2gm: 'Comércio',
  n84gmz: 'Serviços',
  psoidwm: 'Comércio',
  pfden69: 'Serviços',
  siw3rt: 'Comércio',
  s54ngtu: 'Comércio',
  re584jq: 'Comércio',
  '4b21du': 'Comércio',
  '0hhgv47': 'Comércio',
  u3l5at: 'Comércio',
  fak8rar: 'Comércio',
  q9ws06: 'Serviços',
  '6e9bax9': 'Comércio',
  hgrtwfh: 'Comércio',
  gj00q5: 'Comércio',
  iocfekx: 'Serviços',
  fas4xp: 'Serviços',
  uxb7m6: 'Comércio',
  '2zs3k13': 'Serviços',
  c2vx24: 'Comércio',
  t4qdug7: 'Serviços',
  z1onsh9: 'Serviços',
  u0hry69: 'Serviços',
  r7xpqje: 'Comércio',
  hw16ugi: 'Serviços',
  vrqwnv: 'Comércio',
  b1si6eh: 'Serviços',
  ds2qfn4: 'Serviços',
  isojsyb: 'Serviços',
  '5ozqvbe': 'Serviços',
  qhjlax7: 'Comércio',
  tkvcu9z: 'Comércio',
  j5l3ef: 'Comércio',
  ds5a3xe: 'Comércio',
  zulhxd: 'Serviços',
  pvve27s: 'Serviços',
  dujvaga: 'Serviços',
  rdmuxec: 'Comércio',
  c2jm64: 'Comércio',
  m9c3ifka: 'Comércio',
  '7e64hfn': 'Serviços',
  cdl9ttp: 'Comércio',
  '28sv1j': 'Comércio',
  zepomeo: 'Comércio',
  xpotih: 'Serviços',
  '253r21o': 'Comércio',
  w4575ed: 'Serviços',
  p00vqh: 'Comércio',
  t7os7i: 'Serviços',
  wada1: 'Serviços',
  '67qx06': 'Serviços',
  w9h5rsr: 'Serviços',
  w3998xk: 'Serviços',
  za8kl7c: 'Serviços',
  ah1uegk: 'Serviços',
  i9dny9b: 'Serviços',
  rhajrij: 'Comércio',
  b28dyvf: 'Serviços',
  t3aswy7: 'Serviços',
  '5zakzx6': 'Comércio',
  '1vsve3a': 'Comércio',
  tbxe4g: 'Comércio',
  an9j5lg: 'Comércio',
  '605ykgp': 'Comércio',
  '41927kk': 'Comércio',
  gb8r11: 'Comércio',
  ssyx9t: 'Serviços',
  ujn0ps7: 'Serviços',
  '43ftluv': 'Serviços',
  '98vft1': 'Comércio',
  dz9yg2: 'Serviços',
  xrayqs: 'Comércio',
  '32newrx': 'Comércio',
  frffsok: 'Serviços',
  hmc3i1r: 'Comércio',
  '2jiejix': 'Serviços',
  fuq6w3f: 'Comércio',
  th2wnv7: 'Comércio',
  glxpmit: 'Comércio',
  '12tdt28': 'Comércio',
  re1fs4m: 'Comércio',
  dse1n1: 'Comércio',
  tc21zuu: 'Comércio',
  sqo73w: 'Serviços',
  gela712: 'Comércio',
  '0w771i7': 'Comércio',
  '5j8awcd': 'Comércio',
  okziksi: 'Comércio',
  '53tsica': 'Serviços',
  rw1s32: 'Serviços',
  sm47k7c: 'Comércio',
  i9xb98: 'Comércio',
  k9pqk55: 'Comércio',
  temy0hi: 'Comércio',
  ufcikbv: 'Comércio',
  pjvluyg: 'Comércio',
  p1xcmdg: 'Comércio',
  ywmrtdr: 'Comércio',
  lcd4ac: 'Comércio',
  ngxnp2: 'Comércio',
  '5jekpg9': 'Comércio',
  k6nl7fpi: 'Comércio',
  ldyos7: 'Comércio',
  '0dkmicn': 'Serviços',
  zdgyilb: 'Comércio',
  lkpv6rj: 'Comércio',
  '3d4gbvp': 'Serviços',
  k18pube: 'Serviços',
  '5qaih': 'Comércio',
  xga0fj: 'Serviços',
  '7qsmp7l': 'Serviços',
  rr31fmc: 'Comércio',
  '34lrn6': 'Serviços',
  '2sv4kvi': 'Serviços',
  iiq9iwl: 'Comércio',
  l5gses8: 'Comércio',
  cj53qtl: 'Comércio',
  kb1s74: 'Serviços',
  '79gd8x': 'Serviços',
  '2laeu0n': 'Comércio',
  el3fl5m: 'Serviços',
  hcbw1x7: 'Serviços',
  '6c3qae8x': 'Comércio',
  koc14hf: 'Serviços',
  lxlb0p8: 'Serviços',
  ckdtmeq: 'Comércio',
  '50407mi': 'Serviços',
  wdi4itl: 'Comércio',
  pq4jc4q: 'Comércio',
  k34b30yd: 'Comércio',
  t37r3nh: 'Comércio',
  vy4u3cf: 'Comércio',
  uo7tcsl: 'Comércio',
  subah7: 'Serviços',
  k1f8zw9: 'Serviços',
  hcyzqoj: 'Serviços',
  podsvc: 'Comércio'
}
