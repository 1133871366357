import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    private: {
      user: {}
    },
    keycloak: {},
    pricingBudget: {},
    hiddenNotifications: {},
    defaultProposal: {
        legalName: "",
        document: "",
        name: "",
        email: "",
        birthDate: "" ,
        gender: "" ,
        retired: "",
        income: "",
        insuredCapital: "",
        occupationCode: '',
        mobilePhone: '',
        address: {
          postalCode: '',
          complement: "",
          number: ""
        }
    },
    downtime: [],
    features: [],
    config: {},
    i18n: {},
    preload: {
      occupations: []
    },
    session: {
      device: '',
      version: ''
    }
  },

  mutations: {
    user(store, user) {
      store.private.user = user
    },
    defaultProposal(store, defaultProposal ) {
      store.defaultProposal = defaultProposal
    },


    keycloak(store, keycloak) {
      store.keycloak = keycloak
    },

    pricingBudget(store, pricingBudget) {
      store.pricingBudget = pricingBudget
    },

    hiddenNotifications(store, hiddenNotifications) {
      store.hiddenNotifications = hiddenNotifications
    },

    downtime(store, downtime) {
      store.downtime = downtime
    },

    features(store, features) {
      store.features = features
    },

    i18n(store, i18n) {
      store.i18n = i18n
    },

    preload(store, preload) {
      store.preload[preload.name] = preload.data
    },

    config(store, config) {
      store.config = config
    },

    session(store, session) {
      store.session = session
    }
  },

  actions: {
    setUser(context, token) {
      context.commit('user', {
        ...token
      })
    },
   setDefaultProposal(context, defaultProposal) {
    context.commit('defaultProposal', defaultProposal)
   },
    setPricingBudget(context, pricingBudget) {
      context.commit('pricingBudget', pricingBudget)
    },

    setDowntime(context, downtime) {
      context.commit('downtime', downtime)
    },

    setFeatures(context, features) {
      context.commit('features', features)
    },

    setI18n(context, i18n) {
      context.commit('i18n', i18n)
    },

    setPreload(context, preload) {
      context.commit('preload', preload)
    },

    setConfig(context, config) {
      context.commit('config', config)
    },

    setSession(context, session) {
      context.commit('session', session)
    }
  },

  getters: {
    getUser: (store) => store.private.user,
    getPricingBudget: (store) => store.pricingBudget,
    getDefaultProposal: (store) => store.defaultProposal,
    getKeycloak: (store) => store.keycloak,
    getHiddenNotifications: (store) => store.hiddenNotifications,
    getDowntime: (store) => store.downtime,
    getFeatures: (store) => store.features,
    getI18n: (store) => store.i18n,
    getPreload: (store) => store.preload,
    getConfig: (store) => store.config,
    getSession: (store) => store.session
  },

  plugins: [createPersistedState()]
})
