const windowDOM = window
const marcopoloHost = process.env.MARCOPOLO_HOST


const on = (callback) => {
  windowDOM.onmessage = (event) => {
    if (event) {
      callback(event);
    }
  };
};

const emit = (call, value) => {
  windowDOM.top.postMessage({ call, value }, marcopoloHost);
};

export default { on, emit };
