await (async function () {
  await import('@/plugins')
})()

import Vue from 'vue'
import '@babel/polyfill'
import App from './App.vue'
import store from './store'
import router from './router'
import './plugins/ui/vue-hotjar'
import vuetify from './plugins/ui/vuetify'
import packageInfo from '../package.json'
import eventSocket from './socket'

import '@mdi/font/css/materialdesignicons.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import './assets/style/notification.styl'
import './assets/style/custom.styl'

import '@/components'

import Keycloak from 'keycloak-js'
import '@fortawesome/fontawesome-free'

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

import 'tiptap-vuetify/dist/main.css'

import VueObserveVisibility from 'vue-observe-visibility'
import { get } from 'lodash'

Vue.config.productionTip = false

Vue.use(TiptapVuetifyPlugin, {
  lang: 'ptbr',
  vuetify,
  iconsGroup: 'mdi'
})

Vue.use(VueObserveVisibility)

const device = get(navigator, 'platform', 'unknown').toLowerCase()
const version = get(packageInfo, 'version', 'unknown')
store.commit('session', {
  device,
  version
})

function renderApp() {
  window.App = new Vue({
    router,
    store,
    vuetify,
    render: (create) => create(App)
  }).$mount('#app')
}

function logInKeycloak() {
  const keycloak = new Keycloak({
    realm: 'sovereign',
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: 'budget'
  })

  keycloak
    .init({ onLoad: 'login-required' })
    .then((authenticated) => {
      if (!authenticated) {
        window.location.reload()
      } else {
        store.commit('keycloak', keycloak)
        renderApp()
      }

      setInterval(() => {
        keycloak
          .updateToken(60)
          .then((refreshed) => {
            if (refreshed) {
              store.commit('keycloak', keycloak)
            }
          })
          .catch((e) => {
            console.error(e, 'Failed to refresh token')
          })
      }, 60 * 1000)
    })
    .catch((error) => console.error('error', error))
}

function loadingFromMarcopolo({ data }) {
  try {
    const defaultProposal = store.state.defaultProposal
    store.commit('defaultProposal', {...defaultProposal, ...data.payload})
  } catch (error) {
    console.error('error', error)
  }
}

const query = new URLSearchParams(window.location.search)
const embeddedOrigin = query.get('origin') === 'marcopolo'
 if (embeddedOrigin) {
  eventSocket.on((a) => {
    loadingFromMarcopolo(a)
  })
 }
logInKeycloak()


